import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row justify-content-center" }
const _hoisted_2 = { class: "col-12 col-lg-6 col-xl-4" }
const _hoisted_3 = {
  key: 0,
  class: "card border-light text-center py-5"
}
const _hoisted_4 = {
  key: 0,
  class: "card-body"
}
const _hoisted_5 = {
  key: 1,
  class: "card-body"
}
const _hoisted_6 = { class: "card card-body" }
const _hoisted_7 = ["src"]
const _hoisted_8 = {
  key: 1,
  class: "alert alert-dark text-white text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TwoFAUpdate = _resolveComponent("TwoFAUpdate")!
  const _component_HourglassLoader = _resolveComponent("HourglassLoader")!

  return (_openBlock(), _createBlock(_component_HourglassLoader, { loading: _ctx.loading }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.data)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                (_ctx.data?.is_enabled)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "mb-3" }, [
                        _createElementVNode("small", { class: "text-success" }, "You have 2FA enabled")
                      ], -1)),
                      _createVNode(_component_TwoFAUpdate, {
                        is_enabled: true,
                        onUpdated: _ctx.RefreshData
                      }, null, 8, ["onUpdated"])
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _createElementVNode("div", _hoisted_6, [
                        _cache[2] || (_cache[2] = _createElementVNode("small", {
                          class: "text-danger",
                          style: {"font-size":"small"}
                        }, "Please write down the secret key below and keep it safe from third party! It is the only way you can access your account if you lost your device", -1)),
                        _createElementVNode("h6", null, _toDisplayString(_ctx.data.secret), 1)
                      ]),
                      _cache[3] || (_cache[3] = _createElementVNode("div", null, [
                        _createElementVNode("small", null, " Please scan the barcode below using your authenticator App (e.g Google Authenticator) ")
                      ], -1)),
                      _createElementVNode("img", {
                        src: _ctx.data?.url,
                        class: "mx-auto my-3",
                        alt: "2fa Data"
                      }, null, 8, _hoisted_7),
                      _createVNode(_component_TwoFAUpdate, {
                        is_enabled: false,
                        onUpdated: _ctx.RefreshData
                      }, null, 8, ["onUpdated"]),
                      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "text-info mt-4 fw-bolder" }, [
                        _createElementVNode("small", null, "Please note that you'll be required to complete login with a one time code from your authenticator app everytime")
                      ], -1))
                    ]))
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _cache[5] || (_cache[5] = _createElementVNode("p", null, "Currently unable to verify 2FA status", -1)),
                _createElementVNode("button", {
                  class: "btn btn-sm btn-light",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.RefreshData && _ctx.RefreshData(...args)))
                }, " Retry ")
              ]))
        ])
      ])
    ]),
    _: 1
  }, 8, ["loading"]))
}