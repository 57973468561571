
import { useStore } from "vuex";
import HourglassLoader from "@/components/loader/HourglassLoader.vue";
import { ref, defineComponent, onBeforeMount } from "vue";
import { apiGet } from "@/api";
import { Toast } from "@/utils/notify";
import TwoFAUpdate from "@/components/profile/TwoFAUpdate.vue";

export default defineComponent({
  name: "2FAuthentication",
  components: { TwoFAUpdate, HourglassLoader },
  setup() {
    const store = useStore();
    const loading = ref(false);
    const data = ref(null);

    const RefreshData = async () => {
      loading.value = true;
      try {
        const response = await apiGet(`/user-account/${store.getters.id}/2fa`);
        data.value = response.data.data;
        Toast.fire({
          icon: "success",
          title: "Fetched Successfully",
        }).then();
      } catch (e:any) {
        Toast.fire({
          icon: "warning",
          title: "Unable to fetch 2fa status: " + e.message,
        }).then();
      }
      loading.value = false;
    };

    onBeforeMount(RefreshData);

    return {
      store,
      data,
      loading,
      RefreshData,
    };
  },
});
