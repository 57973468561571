import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "mt-3 text-center" }
const _hoisted_4 = {
  type: "submit",
  class: "btn btn-dark"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputWrapper = _resolveComponent("InputWrapper")!
  const _component_DefaultLoader = _resolveComponent("DefaultLoader")!

  return (_openBlock(), _createBlock(_component_DefaultLoader, { loading: _ctx.loading }, {
    default: _withCtx(() => [
      _createElementVNode("form", {
        onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.Submit && _ctx.Submit(...args)), ["prevent"]))
      }, [
        (_ctx.is_enabled)
          ? (_openBlock(), _createElementBlock("small", _hoisted_1, "Enter the Timebased OTP generated and click disable to deactivate 2fa"))
          : (_openBlock(), _createElementBlock("small", _hoisted_2, "Enter the Timebased OTP generated and click enable to activate 2fa")),
        _createVNode(_component_InputWrapper, null, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("input", {
              class: "form-control",
              id: "otp",
              type: "number",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.code) = $event)),
              placeholder: "Enter OTP",
              required: ""
            }, null, 512), [
              [_vModelText, _ctx.formData.code]
            ])
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("button", _hoisted_4, _toDisplayString(_ctx.is_enabled ? "Disable 2FA" : "Enable 2FA"), 1)
        ])
      ], 32)
    ]),
    _: 1
  }, 8, ["loading"]))
}